
@media only screen and (min-width: 0) {
  .landingPageContent {
    zoom: 0.8;
    padding-top: 2vh;
  }
}

@media only screen and (min-width: 1500px) {
  .landingPageContent {
    zoom: 1;
  }
}

.landingPageContent {
  min-width: 100%;
  min-height: 100%;
}

html, body {
  min-height: 100%;
  min-width: 100%;
}

body {
  background: url(../../global-components/layout/images/pattern.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}