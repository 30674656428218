.button {
  display: inline-block;
  width: 15rem;
  margin-top: 2rem;
  padding: 0.75em;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1em;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  /* background-color: #031454; */
}

.button:hover,
.button:active {
  background-color: #a0804d;
}

.buttonBlock {
  display: block;
  width: 100%;
}

.gold {
  background-color: #a0804d;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}

.lightNavy,
.lightNavy:hover,
.gold:active {
  background-color: #755e38;
}
