@media only screen and (min-width: 0px) {
  .hero {
    padding: 24px;
    text-align: center;
  }

  .bullets {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 10px;
    
  }
}

@media only screen and (min-width: 500px) {
  .hero {
    padding: 32px;
    text-align: center;
  }

  .bullets {
    margin-left: 20px;
  }
}

#heropara {
  text-align: left;
}

.resourceContainer {
  margin-top: 1em;
}

.resource {
  padding-top: 2.5em;
  align-items: center;
}

.resourceContent {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  margin-bottom: 1em;
  width: 100%;
}

.ctaExampleReport {
  margin-top: -2.5em;
  padding-bottom: 2.5em;
}
