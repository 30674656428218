@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.error-404_error404Container__7ws-f {
  display: flex;
  align-items: center;
  padding: 2em 2em 0;
}

.layout_layout__2Digl {
  height: 100%;
  width: 100%;
}

.wrap_wrap__1kCsp {
  max-width: 640px;
  margin: 0 auto;
}

@media only screen and (min-width: 0px) {
  .hero_hero__3GujM {
    padding: 24px;
    text-align: center;
  }

  .hero_bullets__3yc8f {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 10px;
    
  }
}

@media only screen and (min-width: 500px) {
  .hero_hero__3GujM {
    padding: 32px;
    text-align: center;
  }

  .hero_bullets__3yc8f {
    margin-left: 20px;
  }
}

#hero_heropara__2ix2f {
  text-align: left;
}

.hero_resourceContainer__1Jidd {
  margin-top: 1em;
}

.hero_resource__12wgj {
  padding-top: 2.5em;
  align-items: center;
}

.hero_resourceContent__14o2p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  margin-bottom: 1em;
  width: 100%;
}

.hero_ctaExampleReport__3EHIj {
  margin-top: -2.5em;
  padding-bottom: 2.5em;
}

.button_button__mr9Nr {
  display: inline-block;
  width: 15rem;
  margin-top: 2rem;
  padding: 0.75em;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1em;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  /* background-color: #031454; */
}

.button_button__mr9Nr:hover,
.button_button__mr9Nr:active {
  background-color: #a0804d;
}

.button_buttonBlock__ybS6X {
  display: block;
  width: 100%;
}

.button_gold__1KPD_ {
  background-color: #a0804d;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}

.button_lightNavy__1HulH,
.button_lightNavy__1HulH:hover,
.button_gold__1KPD_:active {
  background-color: #755e38;
}

.loader_loader__9P5B7 {
  border: 16px solid rgba(100, 100, 100, 0.5);
  border-top: 16px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: loader_spin__CocbX 2s linear infinite;
          animation: loader_spin__CocbX 2s linear infinite;
  zoom: 0.2;
  opacity: 0.8;
  margin: auto;
}

@-webkit-keyframes loader_spin__CocbX {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loader_spin__CocbX {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.form-elements_formGroup__14yUf {
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  text-align: right;
}

.form-elements_element__W2Ivm {
  display: flex;
  padding: 0 0.75em;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: rgba(0, 0, 0, 0.05);
  background-color: white;
}

.form-elements_checkboxElement__VycCC {
  display: flex;
  align-items: center;
  margin: 1em 0 0 0.75em;
}

.form-elements_formGroup__14yUf .form-elements_element__W2Ivm:first-of-type {
  border-radius: 4px 4px 0 0;
}

.form-elements_formGroup__14yUf .form-elements_element__W2Ivm:last-of-type {
  border-bottom-width: 1px;
  border-radius: 0 0 4px 4px;
}

@media only screen and (min-width: 0px) {
  .form-elements_label__3ZgL1,
  .form-elements_input__1R4fl,
  .form-elements_cardLabel__2atX5 {
    display: block;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    padding-top: 0.1em;
    line-height: 2.5em;
    color: rgba(0, 0, 0, 0.5);
    width: auto;
    margin-left: 20px;
  }

  .form-elements_label__3ZgL1 {
    font-size: 0.9em;
    padding-top: 0.1em;
    min-width: 9em;
  }

  .form-elements_checkboxLabel__Vd5lQ {
    padding-left: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.3em;
  }

  .form-elements_cardLabel__2atX5 {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .form-elements_label__3ZgL1,
  .form-elements_input__1R4fl,
  .form-elements_cardLabel__2atX5 {
    font-size: 1em;
    padding-top: 0;
  }

  .form-elements_checkboxLabel__Vd5lQ {
    line-height: 2.5em;
  }

  .form-elements_cardLabel__2atX5 {
    display: block;
    min-width: 9em;
  }
}

.form-elements_input__1R4fl {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
}

.form-elements_input__1R4fl::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-elements_input__1R4fl:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-elements_input__1R4fl::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-elements_input__1R4fl::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-elements_input__1R4fl:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-elements_eulaLink__1-3F5 {
  color: #a0804d;
  cursor: pointer;
  text-decoration: none;
}


@media only screen and (min-width: 0) {
  .crr-report_landingPageContent__5SCF7 {
    zoom: 0.8;
    padding-top: 2vh;
  }
}

@media only screen and (min-width: 1500px) {
  .crr-report_landingPageContent__5SCF7 {
    zoom: 1;
  }
}

.crr-report_landingPageContent__5SCF7 {
  min-width: 100%;
  min-height: 100%;
}

html, body {
  min-height: 100%;
  min-width: 100%;
}

body {
  background: url(/static/media/pattern.52cb54f3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #031454;
  font-size: 100%;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2em;
  padding-bottom: 2em;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.375em;
  margin-bottom: 1.5em;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
}

p {
  margin-bottom: 0.5em;
}

