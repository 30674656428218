.loader {
  border: 16px solid rgba(100, 100, 100, 0.5);
  border-top: 16px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  zoom: 0.2;
  opacity: 0.8;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}