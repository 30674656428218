.formGroup {
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  text-align: right;
}

.element {
  display: flex;
  padding: 0 0.75em;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: rgba(0, 0, 0, 0.05);
  background-color: white;
}

.checkboxElement {
  display: flex;
  align-items: center;
  margin: 1em 0 0 0.75em;
}

.formGroup .element:first-of-type {
  border-radius: 4px 4px 0 0;
}

.formGroup .element:last-of-type {
  border-bottom-width: 1px;
  border-radius: 0 0 4px 4px;
}

@media only screen and (min-width: 0px) {
  .label,
  .input,
  .cardLabel {
    display: block;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    padding-top: 0.1em;
    line-height: 2.5em;
    color: rgba(0, 0, 0, 0.5);
    width: auto;
    margin-left: 20px;
  }

  .label {
    font-size: 0.9em;
    padding-top: 0.1em;
    min-width: 9em;
  }

  .checkboxLabel {
    padding-left: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.3em;
  }

  .cardLabel {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .label,
  .input,
  .cardLabel {
    font-size: 1em;
    padding-top: 0;
  }

  .checkboxLabel {
    line-height: 2.5em;
  }

  .cardLabel {
    display: block;
    min-width: 9em;
  }
}

.input {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.eulaLink {
  color: #a0804d;
  cursor: pointer;
  text-decoration: none;
}
